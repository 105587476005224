
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import CreateEditDialog from '@/components/dialog/CreateEditDialog.vue';
import NodeForm from '@/components/node/NodeForm.vue';
import useNode from '@/components/node/node';

export default defineComponent({
  name: 'CreateEditProjectDialog',
  components: {
    CreateEditDialog,
    NodeForm,
  },
  setup() {
    // store
    const store = useStore();

    return {
      ...useNode(store),
    };
  },
});
