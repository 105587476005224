
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import useNode from '@/components/node/node';
import TagInput from '@/components/input/TagInput.vue';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import NodeType from '@/components/node/NodeType.vue';
import Switch from '@/components/switch/Switch.vue';

export default defineComponent({
  name: 'NodeForm',
  props: {
    readonly: {
      type: Boolean,
    }
  },
  components: {
    Switch,
    NodeType,
    Form,
    FormItem,
    TagInput,
  },
  setup(props, {emit}) {
    // store
    const store = useStore();

    return {
      ...useNode(store),
    };
  },
});
